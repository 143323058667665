import React from 'react';

import {Dialog, IconButton, Tooltip} from '@material-ui/core';

import EditableTable from '../../../Component/Table/EditableTable';

import './CompUniversityProcess.scss';

import {fn, } from '../../../Functions';
import { readCompetitionDataForNEW } from './Data/CompUniversityDataForNEW';
import AnalysisPreview from './AnalysisPreview';
import NDA from './NDA';

const competitionGroups = [{}]

const UniversityForNEW = ({isNEWProduction, ndaConfirmed, setNdaConfirmed}) => {

    if (!isNEWProduction){
        fn.goto('/challenge');
    }

    const [askStartAnalysis, setAskStartAnalysis] = React.useState(false);
    const [currentAnalysisTarget, setCurrentAnalysisTarget] = React.useState({});

    let [candidates, setCandidates] = React.useState([]);
    let [results, setResults] = React.useState([]);
    let [titleInfo, setTitleInfo] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            let {CompUnivDataForNew, CompUnivResult, CompUnivTitle} = await readCompetitionDataForNEW();
            setCandidates(CompUnivDataForNew);
            setResults(CompUnivResult.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {}));
            setTitleInfo(CompUnivTitle.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {}));
        })();
    },[])

    const showSchool = true;
    const showAuthor = true;
    const showEmail = false;

    const argumentedsetNdaConfirmed = (newNDA)=>{
        (async()=>{
            let {CompUnivDataForNew, CompUnivResult, CompUnivTitle} = await readCompetitionDataForNEW();
            setCandidates(CompUnivDataForNew);
            setResults(CompUnivResult.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {}));
            setTitleInfo(CompUnivTitle.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {}));
        })();

        setNdaConfirmed(newNDA);
    }

    if (!ndaConfirmed){
        return <NDA setNdaConfirmed={argumentedsetNdaConfirmed}/>;
    }

    return (<div className={"Competition"}>
        {competitionGroups.map(() => {
            let startTS = new Date("2022-12-19 00:00:00+09:00").getTime()/1000;
            let endTS = new Date("2023-01-01 00:00:00+09:00").getTime()/1000;
            let weekIter = "과제부활전";
            let subsetCandidates = candidates;

            return <div key={`${weekIter} subset`} className={"CompetitionSubset"}>
                <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`전체: ${num} 개`:``))(subsetCandidates.length)
                    } ${
                        ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                    } ${
                        ((num)=>(num>0?`검토중: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultNotifiedAt && row.isValid && row.resultConfirmedAt).length)
                    } ${
                        ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultNotifiedAt || (row.validatedAt && !row.isValid)).length)
                    }`}</span>
                <EditableTable
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    defaultSortCol={[3, "desc"]}
                    data={subsetCandidates
                        .map(candidate => {
                            let title = {...(titleInfo[`${candidate.serviceId}:${candidate.titleId}`] || {})};
                            if ((startTS > candidate.createdAt || candidate.createdAt > endTS) && title.title){
                                title.title = "(기간외)"+title.title;
                            }
                            let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                            return {...title, ...candidate, ...result};
                        })}
                    columnHeaders={["제목", ...([
                        showSchool?"학교":"", showAuthor?"작가명":"", showEmail?"이메일":"",
                        ].filter(term => term)),
                        "총독자", "광고전환율", "완독률", "연독률", "수상 여부"]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, field: "title", type: 'default', style: {width: '20vw', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                            defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title || `${row.author}의 작품` || '(삭제됨)'}</div>
                                <div>
                                    <IconButton onClick={()=>{setAskStartAnalysis(true); setCurrentAnalysisTarget(row)}} size={"small"}>🎞️</IconButton>
                                    <IconButton href={`/challenge/report/${row.serviceId}/${row.titleId}/${row.episodeId}/${row.snapshotId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                                </div>
                            </div></Tooltip> },
                        ...showSchool?[{isEditing: false, field: "schoolInfo", defaultValue: row.schoolInfo, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        ...showAuthor?[{isEditing: false, field: "author", defaultValue: row.author, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        ...showEmail?[{isEditing: false, field: "email", defaultValue: row.email, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        {isEditing: false, field: "readCount", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: row.readCount,},
                        {isEditing: false, field: "CTR", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: `${('' + row.CTR * 100).substring(0,3)}%`,},
                        {isEditing: false, field: "fullReadRate", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: `${('' + row.fullReadRate * 100).substring(0,4)}% `,},
                        {isEditing: false, field: "nextClickRate", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: `${('' + row.nextClickRate * 100).substring(0,4)}%`,},
                        {isEditing: (row.isValid || !row.validatedAt) && !row.resultNotifiedAt, field: "isWinner", type: 'select', style: {whiteSpace: 'nowrap', textAlign: 'center',}, fullWidth: true,
                            defaultValue: row.isWinner || "fail",  options:[{value: "unqualified", key: "🥀과락"}, {value: "fail", key: "🥀"}, {value: "topclass", key: "🏅대상"}, {value: "first", key: "🏅최우수상"}, {value: "runnerup", key: "🏅우수상"}, {value: "class_first", key: "🏅학내최우수상"}, {value: "class_runnerup", key: "🏅학내우수상"}],},
                    ]}
                    updateGenerator={(row, index)=> (newValue)=>{}}
                />
            </div>
        })}
        <Dialog open={askStartAnalysis} maxWidth={"md"} onClose={()=>{setAskStartAnalysis(false); setCurrentAnalysisTarget({})}}>
            <AnalysisPreview
                candidate={currentAnalysisTarget}
                callback={()=>{ }}/>
        </Dialog>
    </div>);
}

export default UniversityForNEW;