import React from 'react';
import './SendEmail.scss'
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';



const FilteredApplication = ({serviceId, titleId, title, author, email, sendCallback}) => {

    const [reason, setReason] = React.useState('');
    const [additionalMessage, setAdditionalMessage] = React.useState("");

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px;">
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                보내주신 &lt;${title}&gt; 작품을 검토한 결과, 데이터 분석(또는 공모전) 심의가 어려워 아래와 같이 안내 드립니다. <br/>
                <br/>
                시장 분석은 작품 줄거리, 소재, 작화 등 작품 전체에 대한 이해를 바탕으로 분석을 진행하기 때문에 다음의 경우 분석이 불가능합니다.<br/>
                <ul>
                    <li style="color: ${reason === '내용미흡'?'rgb(255,108,108)':'rgb(51,51,51)'}" >
                        <b>내용 파악이 어려운 원고${reason === '내용미흡'?' ◀ 해당 사유':''}</b>
                    </li>
                    <ul><li>줄거리나 원고로 작품의 내용을 파악할 수 없는 경우</li></ul>

                    <li style="color: ${reason === '미완성'?'rgb(255,108,108)':'rgb(51,51,51)'}" >
                        <b>완성되지 않은 원고${reason === '미완성'?' ◀ 해당 사유':''}</b>
                    </li>
                    <ul><li>콘티, 스케치 단계인 경우</li></ul>

                    <li style="color: ${reason === '권리없음'?'rgb(255,108,108)':'rgb(51,51,51)'}" >
                        <b>게재, 송출이 불가한 원고${reason === '권리없음'?' ◀ 해당 사유':''}</b>
                    </li>
                    <ul><li>독점 게재(연재) 계약이 체결되거나 신청자에게 작품에 대한 권리가 없는 경우</li></ul>
                </ul>
                <b>${additionalMessage.replace(/\n/g,"<br/>")}</b><br/>
                위의 사항을 고려하셔서, 수정 후 재신청을 부탁드립니다.<br/>
                결제는 취소 처리해드렸으며, 카드 결제 환불은 X일이 소요될 수 있습니다.
                <br/>
                감사합니다.<br/>
                오늘의웹툰 팀 드림.<br/><br/>
                <a href="https://challenge.webtoon.today/contents/${serviceId}/${titleId}" target="_blank" rel="noopener" style="color: white; background-color: rgb(0,176,240); width: 140px; font-size: 16px; padding: 10px 15px; border: 0px; border-radius: 4px; text-align: center; text-decoration: none;">다시 신청하기 </a>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)<br/>
                작품문의: <a href="mailto:artist@webtoon.today">artist@webtoon.today</a><br/>
                기타문의: <a href="mailto:help@webtoon.today">help@webtoon.today</a><br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] 시장 분석 원고 검토 결과 공지`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                            <tr>
                                <td>{"반려 이유"}</td>
                                <td>
                                    <Select value={reason} onChange={(e)=> setReason(e.target.value)}>
                                        <MenuItem value={"내용미흡"} >{"내용 파악이 어려운 원고"}</MenuItem>
                                        <MenuItem value={"미완성"} >{"완성되지 않은 원고"}</MenuItem>
                                        <MenuItem value={"권리없음"} >{"게재, 송출이 불가능한 원고"}</MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td>{"추가 안내사항(선택)"}</td>
                                <td><TextField fullWidth multiline value={additionalMessage} onChange={(event)=>{setAdditionalMessage(event.target.value)}}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!title || !author || !email || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result, `${reason}${additionalMessage?`(${additionalMessage})`:``}`);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default FilteredApplication ;