import React from 'react';
import './SendEmail.scss'
import { Button } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';


const ConfirmedApplication = ({serviceId, titleId, title, author, email, sendCallback}) => {

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px;">
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                <${title}>의 투고가 정상적으로 접수되었습니다!<br/>
                <br/>
                원하는 플랫폼을 선택하시면 분석 결과를 확인하실 수 있습니다.<br/>
                <a href=${`https://challenge.webtoon.today/dashboard/${serviceId}/${titleId}}`} target="_blank" rel="noopener" style="text-decoration: none; color: rgb(204, 162, 80);">https://challenge.webtoon.today/dashboard/${serviceId}/${titleId}}</a><br/>
                <br/>
                확인해보시고 이해가 안 가는 부분이 있다면 사이트 우측 하단의 채널톡으로 문의해주세요. 확인 후 빠르게 답변드리겠습니다.<br/>
                <br/>
                ${author} 작가님의 작품 활동에 도움이 되기를 진심으로 바랍니다. 오늘의 웹툰은 언제나 작가님의 창작을 응원하겠습니다!<br/>
                <br/>
                감사합니다.<br/><br/>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)<br/>
                작품문의: <a href="mailto:artist@webtoon.today">artist@webtoon.today</a><br/>
                기타문의: <a href="mailto:help@webtoon.today">help@webtoon.today</a><br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] 시장 분석 결과를 보내드립니다.`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!title || !author || !email || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default ConfirmedApplication ;