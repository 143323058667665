import React from 'react';
import './SendEmail.scss'
import { Button, TextField } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';
import ss from '../../../../Data/Session';


const SubmittedApplication = ({serviceId, titleId, title, author, email, sendCallback}) => {

    const [additionalMessage, setAdditionalMessage] = React.useState("");

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px;">
                안녕하세요 ${author} 작가님.<br/> 
                오늘의웹툰 ${ss.getCurrentSession().name} 디렉터입니다.<br/>
                <br/>
                우선, 소중한 작품을 저희 회사에 투고해 주신 점에 진심으로 감사 인사를 전합니다.<br/>
                투고해 주신 <${title}>의 기획서와 원고를 내부적으로 검토하였으며 이에 내부의견을 전달해 드리고자 합니다.<br />
                송구스럽게도 이번 작품에 에이전시 계약을 제안드리기 어렵다는 말씀을 전하게 되었습니다.<br/>
                <br/>
                내부 논의 결과에 관해 간략히 말씀 드리겠습니다.<br />
                <b>${additionalMessage.replace(/\n/g,"<br/>")}</b><br/>
                <br/>
                이상 저희 내부 의논 결과와 작품에 대한 의견을 전달해 드렸습니다.<br/>
                <br/>
                귀한 작품을 투고해주신 작가님께 좋은 결과를 전해드리지 못해 죄송하다는 말씀을 드리며, <br/>
                좋은 기회에 다시 뵐 수 있기를 기원하겠습니다.<br/>
                <br/>
                감사합니다.<br/>
                ${ss.getCurrentSession().name} 드림.<br/><br/>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)<br/>
                작품문의: <a href="mailto:artist@webtoon.today">artist@webtoon.today</a><br/>
                기타문의: <a href="mailto:help@webtoon.today">help@webtoon.today</a><br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[투고 결과 안내] 안녕하세요, ${author} 작가님, 웹툰 에이전시 오늘의웹툰입니다 :)`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                            <tr>
                                <td>{"추가 안내사항(선택)"}</td>
                                <td><TextField fullWidth multiline value={additionalMessage} onChange={(event)=>{setAdditionalMessage(event.target.value)}}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!title || !author || !email || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default SubmittedApplication ;