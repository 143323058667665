
import axios from 'axios'
import ss from '../../../../Data/Session';

export const readCompetitionDataForNEW = async () => {
    if ( !ss.getCurrentSession().userid ){
        return [];
    }
    let res = await axios.post('https://api.webtoon.today/competition-for-new', {}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}
