import React from 'react';
import './SendEmail.scss'
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { triggerEmail } from '../../Data/TriggerEmail';


const FilteredApplication = ({title, author, email, sendCallback}) => {

    const [reason, setReason] = React.useState('');
    const [additionalMessage, setAdditionalMessage] = React.useState("");

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px;">
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                보내주신 &lt;${title}&gt; 작품을 검토한 결과, 데이터 분석(또는 공모전) 심의가 어려워 아래와 같이 안내 드립니다. <br/>
                <br/>
                오늘의웹툰 데이터 분석 서비스 이용을 위해서는 <strong>다음 조건을 만족하는 1화 이상의 원고</strong>가 필요합니다.<br/>
                데이터 분석은 SNS 광고 등을 통해 실제 독자 30~100여 명을 모집하여 작품을 읽게 하고 그 활동을 분석하는 방식으로 이루어지고 있습니다. 따라서 다음의 경우 테스트가 불가능한 점 알려드립니다.<br/>
                <ul>
                    <li><b>완성되지 않은 원고${reason === '미완성'?' <= (해당)':''}</b><br/>- 콘티, 스케치, 채색이 안된 경우</li>
                    <li><b>형식이 맞지 않는 원고${reason === '형식/분량문제'?' <= (해당)':''}</b><br/>- 원고 길이가 60컷 이하이거나, 스크롤방식으로 보기 불가능한 경우</li>
                    <li><b>광고가 불가능한 원고${reason === '광고불가'?' <= (해당)':''}</b><br/>- 폭력성, 선정성 등의 이유로 19세 미만 이용 불가한 경우</li>
                    <li><b>게재, 송출이 불가한 원고${reason === '권리없음'?' <= (해당)':''}</b><br/>- 독점 게재(연재) 계약이 체결되거나 신청자에게 작품에 대한 권리가 없는 경우</li>
                    <li><b>이미 데이터 분석이 이루어진 원고${reason === '중복원고'?' <= (해당)':''}</b><br/>- 리메이크 등으로 원고가 크게 수정된 경우에는 허용됨</li>
                </ul>
                <b>${additionalMessage.replace(/\n/g,"<br/>")}</b><br/>
                위와 같은 사항 고려하셔서, 원고 수정 후 재신청 부탁드립니다.<br/>
                <br/>
                감사합니다.<br/>
                오늘의웹툰 팀 드림.<br/><br/>
                <a href="https://webtoon.today" target="_blank" rel="noopener" style="color: white; background-color: rgb(0,176,240); width: 140px; font-size: 16px; padding: 10px 15px; border: 0px; border-radius: 4px; text-align: center; text-decoration: none;">다시 신청하기 </a>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)<br/>
                작품문의: <a href="mailto:artist@webtoon.today">artist@webtoon.today</a><br/>
                기타문의: <a href="mailto:help@webtoon.today">help@webtoon.today</a><br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] 데이터분석 원고 검토 결과 공지`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                            <tr>
                                <td>{"반려 이유"}</td>
                                <td>
                                    <Select value={reason} onChange={(e)=> setReason(e.target.value)}>
                                        <MenuItem value={"미완성"} >{"완성되지 않은 원고"}</MenuItem>
                                        <MenuItem value={"형식/분량문제"} >{"형식이 맞지 않는 원고"}</MenuItem>
                                        <MenuItem value={"광고불가"} >{"광고가 불가능한 원고"}</MenuItem>
                                        <MenuItem value={"권리없음"} >{"게재, 송출이 불가능한 원고"}</MenuItem>
                                        <MenuItem value={"중복원고"} >{"이미 데이터 분석이 이루어진 원고"}</MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td>{"추가 안내사항(선택)"}</td>
                                <td><TextField fullWidth multiline value={additionalMessage} onChange={(event)=>{setAdditionalMessage(event.target.value)}}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!title || !author || !email || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result, `${reason}${additionalMessage?`(${additionalMessage})`:``}`);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default FilteredApplication ;