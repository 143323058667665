import React from "react";

import './AnalysisLaunching.scss';

import { Avatar, } from "@material-ui/core";
import AdsSlides from "../../AdsSlides";

const AnalysisPreview = ({candidate={}, callback=()=>{}}) => {

    if (!candidate.title){
        return <div></div>;
    }

    return (<div className="AnalysisLaunching">
        <div className={"TitleSection"}>
            <div className={"Title"}><Avatar src={candidate.thumbnail} size={'small'}></Avatar><span>{candidate.title}</span></div>
        </div>
        <AdsSlides title={{...candidate, slides: candidate.slides.map(obj => obj.image)}}/>
    </div>);
}

export default AnalysisPreview;