import { Tooltip } from '@material-ui/core';
import ss from '../../../Data/Session';
import { getMailReadLog } from './Data/MailReadLog';

import "./MailReadLog.scss"

import { useEffect, useState } from "react";

const MailReadLog = () => {

    const [mailReadLog, setMailReadLog] = useState({});

    useEffect(()=>{
        (async() => {
            await ss.sessionRefresh();
            const mailReadLogs = await getMailReadLog();
            let logByTitleUser = {};

            mailReadLogs.map(log => {
                if(!logByTitleUser[log.title]){
                    logByTitleUser[log.title] = {};
                }

                if(!logByTitleUser[log.title][log.name]){
                    logByTitleUser[log.title][log.name] = {};
                }

                logByTitleUser[log.title][log.name][new Date(log.date*1000).toISOString().substring(2,10)] = log.openAt;
            })
            setMailReadLog(logByTitleUser);
        })();
    },[]);


    return (
        <div className={'MailReadLogDashboardContainer'}>
            {Object.entries(mailReadLog).map(([title, readData])=> {
                return (<div className={'TitleArea'} key={title}>
                <div style={{fontSize: 20}}>
                    {title}
                </div>
                {Object.entries(readData).map(([name, data])=>{
                    return (<div key={name} className={'NameArea'}>
                        <Tooltip title={name} >
                            <div style={{width: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all"}}>
                                {name} 
                            </div>
                        </Tooltip>
                        {Object.entries(data).map(([date, openAt])=>{
                        return (<div key={date} className={`DateArea ${openAt?`Read`:`Unread`}`}>
                            <div>{date}</div>
                        </div>)
                    })}</div>)
                })}</div>)
            })}

        </div>    
    )
}

export default MailReadLog;